import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';

function Home() {



    
    return (
        <div class="container">
        
        <img src="./assets/images/Logo-Draft-Black.png" alt="Oh My Goddess Photography Logo" class="logo"/>
        
        
        <h1>Coming Soon</h1>
        <p>We are updating our website and we will be back soon !<br/>
        In the meantime, feel free to get in touch with us via our social media channels below.</p>
        
        
        <div class="social-links">
            <a href="https://facebook.com/omgphotography" target="_blank" aria-label="Facebook">⛩ Facebook</a>
            <a href="https://instagram.com/melbournematernityphotographer" target="_blank" aria-label="Instagram">📸 Instagram</a>
            
        </div>

       <p>You can also call us on +61-422098774</p>
        
        
        <div class="footer">
            &copy; 2024 Oh My Goddess Photography. All Rights Reserved.
        </div>
    </div>

            );
}

export default Home;
